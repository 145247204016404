
export default {
    PrimaryBck :"#234900",
    SecondaryBck :"#9c9507",
    TertiaryBck :"#edecd7",
    primary: '#002D62',
    secondary: "#2A2F3B",
    Charcoal: '#36454F',
    tertiary: '#DDE4EA',
    offwhite: '#ececec',
    offwhite2: '#f2f3f4',
    white: '#ffffff',
    RasinBlack: '#242124',
    MediumGrey: '#6e6969',
    black: 'black',
    AppCanvasMain: '#F2F2F2',
    ForeGroundWhite: '#FAFAFA',
    Grey: 'grey',
    orange: 'orange',
    red: 'red',
    green: 'green',
    yellow: 'yellow'
}